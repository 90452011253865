<template>
  <v-card class="elevation-2 pa-4"> 
    <!-- Features Menu -->
    <CreditScoringFeaturesMenu />

    <v-card-title class="headline d-flex align-center">
      <span>Risk Rating Settings</span>
      <v-spacer></v-spacer>
      <v-btn
        color="primary"
        class="mr-2"
        @click="openAddCategoryDialog"
        v-if="selectedTab === 0"
      >
        <v-icon left>add</v-icon>
        Add Category
      </v-btn>

      <v-btn
        color="primary"
        class="mr-2"
        @click="openGenerateRangesDialog"
        v-if="selectedTab === 1 && selectedCategory"
      >
        <v-icon left>add</v-icon>
        Generate Ranges
      </v-btn>
    </v-card-title>
    
    <v-card-text>
      <p class="subtitle-1 mb-4">Configure risk rating categories and grades based on loan types and score bands.</p>
      
      <!-- Tabs -->
      <v-tabs v-model="selectedTab" background-color="primary" dark>
        <v-tab>Categories</v-tab>
        <v-tab>Risk Ratings</v-tab>
      </v-tabs>
      
      <v-tabs-items v-model="selectedTab">
        <!-- Categories Tab -->
        <v-tab-item>
          <v-form ref="categoryForm" v-model="isCategoryFormValid">
            <!-- Category Table -->
            <v-data-table
              :headers="categoryHeaders"
              :items="riskRatingCategories"
              class="elevation-1 mb-5 mt-4"
              :loading="isLoading"
              :pagination.sync="pagination"
            >
              <template slot="items" slot-scope="props">
                <tr @click="selectCategory(props.item)">
                  <td>{{ props.item.name }}</td>
                  <td>{{ props.item.description }}</td>
                  <td>
                    <v-chip
                      v-for="type in props.item.loanTypes"
                      :key="type"
                      class="ma-1"
                      small
                      dark
                      color="primary"
                    >
                      {{ type }}
                    </v-chip>
                  </td>
                  <td>
                    <v-chip
                      dark
                      :color="props.item.isActive ? 'success' : 'error'"
                      small
                    >
                      {{ props.item.isActive ? 'Active' : 'Inactive' }}
                    </v-chip>
                  </td>
                  <td class="justify-center layout px-0">
                    <v-icon 
                      small
                      class="mr-2" 
                      @click.stop="editCategory(props.item)"
                    >
                      edit
                    </v-icon>
                    <v-icon 
                      small
                      @click.stop="deleteCategory(props.item)"
                    >
                      delete
                    </v-icon>
                  </td>
                </tr>
              </template>
              
              <template slot="no-data">
                <div class="text-center">
                  <p>No risk rating categories configured yet.</p>
                </div>
              </template>
            </v-data-table>
          </v-form>
        </v-tab-item>
        
        <!-- Risk Ratings Tab -->
        <v-tab-item>
          <div class="d-flex align-center mt-4 mb-4">
            <v-select
              v-model="selectedCategory"
              :items="riskRatingCategories"
              item-text="name"
              item-value="_id"
              label="Select Category"
              outline
              :disabled="riskRatingCategories.length === 0"
              @change="onCategoryChange"
            ></v-select>
          </div>
          
          <v-alert
            v-if="!selectedCategory"
            :value="true"
            type="info"
            outline
          >
            Please select a category first or create a new one from the Categories tab.
          </v-alert>
          
          <v-form ref="form" v-model="isFormValid" v-if="selectedCategory">
            <!-- Risk Ratings Table -->
            <v-data-table
              :headers="riskRatingHeaders"
              :items="riskRatings"
              class="elevation-1 mb-5"
              :loading="isLoading"
              :pagination.sync="ratingsPagination"
            >
              <template slot="items" slot-scope="props">
                <tr>
                  <td>{{ props.item.grade }}</td>
                  <td>{{ props.item.definition }}</td>
                  <td>{{ props.item.minScore }}</td>
                  <td>{{ props.item.maxScore }}</td>
                  <td class="justify-center layout px-0">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(props.item)"
                    >
                      edit
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(props.item)"
                    >
                      delete
                    </v-icon>
                  </td>
                </tr>
              </template>
              
              <template slot="no-data">
                <div class="text-center">
                  <p>No risk ratings configured for this category yet.</p>
                </div>
              </template>
            </v-data-table>

            <!-- Visual Representation of Score Bands -->
            <v-card class="mt-4 mb-4" flat v-if="selectedCategory && riskRatings.length > 0">
              <v-card-title>Risk Rating Score Bands</v-card-title>
              <v-card-text>
                <div class="score-bands-container">
                  <div 
                    v-for="(rating, index) in sortedRiskRatings" 
                    :key="index"
                    class="score-band"
                    :style="{
                      width: `${calculateBandWidth(rating)}%`,
                      backgroundColor: getBandColor(index)
                    }"
                  >
                    <div class="band-label">
                      {{ rating.grade }} - {{ rating.definition }}<br>
                      {{ rating.minScore }} to {{ rating.maxScore }}
                    </div>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-form>
        </v-tab-item>
      </v-tabs-items>
      <!-- Category Dialog -->
      <v-dialog v-model="categoryDialog" max-width="600px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ categoryFormTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-text-field
                    v-model="editedCategory.name"
                    label="Category Name"
                    :rules="categoryNameRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-textarea
                    v-model="editedCategory.description"
                    label="Description"
                    rows="2"
                    :rules="categoryDescriptionRules"
                  ></v-textarea>
                </v-flex>
                <v-flex xs12>
                  <v-select
                    v-model="editedCategory.loanTypes"
                    :items="availableLoanTypesForSelection"
                    label="Loan Types"
                    multiple
                    chips
                    :rules="loanTypesRules"
                    required
                    :return-object="false"
                    @change="validateLoanTypeSelections"
                  ></v-select>
                  <v-alert
                    v-if="duplicateLoanTypeError"
                    :value="true"
                    type="warning"
                    outline
                    dense
                    class="mt-2"
                  >
                    {{ duplicateLoanTypeError }}
                  </v-alert>
                  <!-- Info message about loan type filtering -->
                  <v-alert
                    v-if="editedCategoryIndex === -1 && availableLoanTypesForSelection.length < availableLoanTypes.length"
                    :value="true"
                    type="info"
                    outline
                    dense
                    class="mt-2"
                  >
                    Some loan types are not available because they are already used in other categories.
                  </v-alert>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-model="editedCategory.isActive"
                    label="Active"
                    color="success"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
            <v-alert
              v-if="categoryFormError"
              :value="true"
              type="error"
              outline
              dismissible
            >
              {{ categoryFormError }}
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              flat
              @click="closeCategory"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              flat
              @click="saveCategory"
              :disabled="!!duplicateLoanTypeError || !editedCategory.name || !editedCategory.loanTypes || editedCategory.loanTypes.length === 0"
              :loading="isSaving"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- Rating Dialog -->
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="editedItem.grade"
                    label="Risk Rating Grade"
                    :rules="gradeRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model="editedItem.definition"
                    label="Risk Rating Definition"
                    :rules="definitionRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model.number="editedItem.minScore"
                    label="Minimum Score"
                    type="number"
                    min="0"
                    max="1000"
                    :rules="scoreRules"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model.number="editedItem.maxScore"
                    label="Maximum Score"
                    type="number"
                    min="0"
                    max="1000"
                    :rules="scoreRules"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
            <v-alert
              v-if="formError"
              :value="true"
              type="error"
              outline
              dismissible
            >
              {{ formError }}
            </v-alert>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              flat
              @click="close"
            >
              Cancel
            </v-btn>
            <v-btn
              color="blue darken-1"
              flat
              @click="save"
              :disabled="!isFormValid"
              :loading="isSaving"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- Delete Dialog for Risk Ratings -->
      <v-dialog v-model="dialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete this risk rating?</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="closeDelete">Cancel</v-btn>
            <v-btn color="error" flat @click="deleteItemConfirm">Delete</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      
      <!-- Delete Dialog for Categories -->
      <v-dialog v-model="categoryDialogDelete" max-width="500px">
        <v-card>
          <v-card-title class="headline">Are you sure you want to delete this category?</v-card-title>
          <v-card-text class="red--text" style="padding-top: 0;">This will also delete all risk ratings associated with this category!</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" flat @click="closeCategoryDelete">Cancel</v-btn>
            <v-btn color="error" flat @click="deleteCategoryConfirm">Delete</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Generate Ranges Dialog -->
      <v-dialog v-model="generateRangesDialog" max-width="800px">
        <v-card>
          <v-card-title>
            <span class="headline">Generate Risk Rating Ranges</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12>
                  <v-alert
                    :value="true"
                    type="info"
                    outline
                  >
                    This will generate new risk rating grades with evenly distributed score ranges.
                    Existing risk ratings for this category will be replaced.
                  </v-alert>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model.number="rangeGenerator.startValue"
                    label="Starting Value"
                    type="number"
                    min="0"
                    max="1000"
                    :rules="[
                      v => v !== null || 'Starting value is required',
                      v => v >= 0 || 'Starting value must be at least 0',
                      v => v < 1000 || 'Starting value must be less than 1000'
                    ]"
                    required
                    hint="Minimum score value"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12 sm6>
                  <v-text-field
                    v-model.number="rangeGenerator.numRanges"
                    label="Number of Ranges"
                    type="number"
                    min="2"
                    max="10"
                    :rules="[
                      v => v !== null || 'Number of ranges is required',
                      v => v >= 2 || 'At least 2 ranges required',
                      v => v <= 10 || 'Maximum 10 ranges allowed'
                    ]"
                    required
                    hint="How many risk rating grades to create"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-text-field
                    v-model.number="rangeGenerator.interval"
                    label="Interval Size"
                    type="number"
                    min="10"
                    :rules="[
                      v => v !== null || 'Interval size is required',
                      v => v >= 10 || 'Interval must be at least 10',
                      v => (rangeGenerator.startValue + (rangeGenerator.numRanges * v)) <= 1000 || 'Total range exceeds maximum score (1000)'
                    ]"
                    required
                    hint="Size of each score range"
                  ></v-text-field>
                </v-flex>
                <v-flex xs12>
                  <v-switch
                    v-model="rangeGenerator.useLetterGrades"
                    label="Use Letter Grades (A, B, C...)"
                    color="primary"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
            <v-alert
              v-if="rangeGeneratorError"
              :value="true"
              type="error"
              outline
              dismissible
            >
              {{ rangeGeneratorError }}
            </v-alert>
            
            <!-- Preview the generated ranges -->
            <v-card v-if="canGenerateRanges" flat>
              <v-card-title>Preview Generated Ranges</v-card-title>
              <v-card-text>
                <v-data-table
                  :headers="[
                    { text: 'Grade', value: 'grade' },
                    { text: 'Definition', value: 'definition' },
                    { text: 'Min Score', value: 'minScore' },
                    { text: 'Max Score', value: 'maxScore' }
                  ]"
                  :items="previewGeneratedRanges"
                  hide-actions
                  class="elevation-1"
                >
                  <template slot="items" slot-scope="props">
                    <td>{{ props.item.grade }}</td>
                    <td>{{ props.item.definition }}</td>
                    <td>{{ props.item.minScore }}</td>
                    <td>{{ props.item.maxScore }}</td>
                  </template>
                </v-data-table>
              </v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="blue darken-1"
              flat
              @click="closeGenerateRangesDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              color="green darken-1"
              flat
              @click="generateRanges"
              :disabled="!canGenerateRanges"
              :loading="isSaving"
            >
              Generate
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Alerts -->
      <v-alert
        v-if="successMessage"
        :value="true"
        type="success"
        dismissible
        class="mb-4"
      >
        {{ successMessage }}
      </v-alert>
      
      <v-alert
        v-if="errorMessage"
        :value="true"
        type="error"
        dismissible
        class="mb-4"
      >
        {{ errorMessage }}
      </v-alert>

      <error-dialog
        v-model="errorDialog.show"
        :title="errorDialog.title"
        :message="errorDialog.message"
        :details="errorDialog.details"
        :type="errorDialog.type"
        :suggestion="errorDialog.suggestion"
        :show-retry-button="errorDialog.showRetryButton"
        :show-action-button="errorDialog.showActionButton"
        :action-button-text="errorDialog.actionButtonText"
        :action-button-color="errorDialog.actionButtonColor"
        @retry="handleErrorRetry"
        @action="handleErrorAction"
      />
    </v-card-text>
  </v-card>
</template>
<script>
import creditScoringService from '@/services/creditScoringService';
import CreditScoringFeaturesMenu from '@/components/CreditScoringFeaturesMenu.vue';
import ErrorDialog from '@/components/ErrorDialogComponent.vue';

export default {
  name: 'RiskRatingSettings',
  
  components: {
    CreditScoringFeaturesMenu,
    ErrorDialog
  },

  data() {
    return {
      selectedTab: 0,
      riskRatingCategories: [],
      originalRiskRatingCategories: [],
      selectedCategory: null,
      riskRatings: [],
      originalRiskRatings: [],
      dialog: false,
      dialogDelete: false,
      categoryDialog: false,
      categoryDialogDelete: false,
      isFormValid: true,
      isCategoryFormValid: true,
      isSaving: false,
      isLoading: false,
      successMessage: '',
      errorMessage: '',
      formError: '',
      categoryFormError: '',
      duplicateLoanTypeError: '',
      pagination: {
        rowsPerPage: 10
      },
      ratingsPagination: {
        rowsPerPage: 10
      },
      availableLoanTypes: [
        "Motor Vehicle",
        "Real Estate",
        "Cash Secured",
        "Unsecured",
        "Partially Secured",
        "Chattels"
      ],
      
      // Category headers
      categoryHeaders: [
        { text: 'Name', value: 'name' },
        { text: 'Description', value: 'description' },
        { text: 'Loan Types', value: 'loanTypes' },
        { text: 'Status', value: 'isActive' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      
      // Risk rating headers
      riskRatingHeaders: [
        { text: 'Grade', value: 'grade' },
        { text: 'Definition', value: 'definition' },
        { text: 'Min Score', value: 'minScore' },
        { text: 'Max Score', value: 'maxScore' },
        { text: 'Actions', value: 'actions', sortable: false },
      ],
      
      // Category editing
      editedCategoryIndex: -1,
      editedCategory: {
        _id: null,
        name: '',
        description: '',
        loanTypes: [],
        isActive: true
      },
      defaultCategory: {
        _id: null,
        name: '',
        description: '',
        loanTypes: [],
        isActive: true
      },
      
      editedIndex: -1,
      editedItem: {
        _id: null,
        categoryId: null,
        grade: '',
        definition: '',
        minScore: 0,
        maxScore: 1000,
      },
      defaultItem: {
        _id: null,
        categoryId: null,
        grade: '',
        definition: '',
        minScore: 0,
        maxScore: 1000,
      },

      // Validation rules for risk ratings
      gradeRules: [
        v => !!v || 'Grade is required',
        v => v.length <= 10 || 'Grade must be less than 10 characters',
      ],
      definitionRules: [
        v => !!v || 'Definition is required',
        v => v.length <= 50 || 'Definition must be less than 50 characters',
      ],
      scoreRules: [
        v => !!v || 'Score is required',
        v => v >= 0 || 'Score must be at least 0',
        v => v <= 1000 || 'Score must be at most 1000',
      ],
      
      // Validation rules for categories
      categoryNameRules: [
        v => !!v || 'Category name is required',
        v => v.length <= 50 || 'Category name must be less than 50 characters',
      ],
      categoryDescriptionRules: [
        v => !v || v.length <= 255 || 'Description must be less than 255 characters',
      ],
      loanTypesRules: [
        v => v.length > 0 || 'At least one loan type must be selected',
      ],

      errorDialog: {
        show: false,
        title: 'Error',
        message: '',
        details: '',
        type: 'error',
        suggestion: '',
        showRetryButton: false,
        showActionButton: false,
        actionButtonText: 'Action',
        actionButtonColor: 'primary',
        retryCallback: null,
        actionCallback: null
      },

      generateRangesDialog: false,
      rangeGenerator: {
        startValue: 0,
        numRanges: 5,
        interval: 200,
        useLetterGrades: true
      },
      rangeGeneratorError: '',
    };
  },
  
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'New Risk Rating' : 'Edit Risk Rating';
    },
    
    categoryFormTitle() {
      return this.editedCategoryIndex === -1 ? 'New Category' : 'Edit Category';
    },
    
    hasRatingChanges() {
      return JSON.stringify(this.riskRatings) !== JSON.stringify(this.originalRiskRatings);
    },
    
    hasCategoryChanges() {
      return JSON.stringify(this.riskRatingCategories) !== JSON.stringify(this.originalRiskRatingCategories);
    },
    
    sortedRiskRatings() {
      return [...this.riskRatings].sort((a, b) => a.minScore - b.minScore);
    },
    
    canSave() {
      if (this.selectedTab === 0) {
        return this.riskRatingCategories.length > 0;
      } else {
        return this.selectedCategory && this.riskRatings.length > 0;
      }
    },
    
    // Get the currently selected category object
    currentCategory() {
      if (!this.selectedCategory) return null;
      return this.riskRatingCategories.find(cat => cat._id === this.selectedCategory);
    },

    canGenerateRanges() {
      const gen = this.rangeGenerator;
      
      // Basic validation
      if (gen.startValue === null || gen.startValue === undefined ||
          gen.numRanges === null || gen.numRanges === undefined ||
          gen.interval === null || gen.interval === undefined) {
        return false;
      }
      
      // Make sure values are in range
      if (gen.startValue < 0 || gen.startValue >= 1000 ||
          gen.numRanges < 2 || gen.numRanges > 10 ||
          gen.interval < 10) {
        return false;
      }
      
      // Check if total range exceeds maximum
      const totalRange = gen.startValue + (gen.numRanges * gen.interval);
      if (totalRange > 1000) {
        return false;
      }
      
      return true;
    },
    
    previewGeneratedRanges() {
      if (!this.canGenerateRanges) {
        return [];
      }
      
      const result = [];
      const start = this.rangeGenerator.startValue;
      const interval = this.rangeGenerator.interval;
      const numRanges = this.rangeGenerator.numRanges;
      const useLetterGrades = this.rangeGenerator.useLetterGrades;
      
      for (let i = 0; i < numRanges; i++) {
        const minScore = start + (i * interval);
        let maxScore = start + ((i + 1) * interval) - 1;
        
        // Make sure the last range includes the maximum value
        if (i === numRanges - 1) {
          maxScore = 1000;
        }
        
        // Generate appropriate grade based on settings
        const grade = useLetterGrades 
          ? this.getLetterGrade(i, numRanges)
          : `Grade ${i + 1}`;
        
        // Generate definition
        const definition = this.generateRiskRatingDefinition(i, numRanges);
        
        result.push({
          grade: grade,
          definition: definition,
          minScore: minScore,
          maxScore: maxScore
        });
      }
      
      return result;
    },

    availableLoanTypesForSelection() {
      // If we're editing an existing category, get its ID
      const currentCategoryId = this.editedCategory._id;
      
      // Get all loan types used in existing categories (except the current one if editing)
      const usedLoanTypes = this.riskRatingCategories
        .filter(category => category._id !== currentCategoryId) // Exclude current category if editing
        .flatMap(category => category.loanTypes || []);
      
      // Get unique used loan types (in case there are any duplicates)
      const uniqueUsedLoanTypes = [...new Set(usedLoanTypes)];
      
      // Filter out already used loan types from available options
      return this.availableLoanTypes.filter(
        loanType => !uniqueUsedLoanTypes.includes(loanType)
      );
    }
  },
  
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
    categoryDialog(val) {
      val || this.closeCategory();
    },
    categoryDialogDelete(val) {
      val || this.closeCategoryDelete();
    },
    selectedTab(val) {
      // Clear messages when switching tabs
      this.successMessage = '';
      this.errorMessage = '';
    }
  },
  created() {
    // Set loading state
    this.initialize();
  },
  
  mounted() {
    console.log("Component mounted, data loaded:", {
      categories: this.riskRatingCategories,
      ratings: this.riskRatings,
      selectedCategory: this.selectedCategory
    });
    
    // Force update to ensure rendering
    this.$nextTick(() => {
      this.$forceUpdate();
    });
  },
  
  methods: {
    async initialize() {
      this.isLoading = true;
      
      try {
        // First fetch all categories
        await this.fetchCategories();
        
        // If there are categories, select the first one and load its ratings
        if (this.riskRatingCategories.length > 0) {
          this.selectedCategory = this.riskRatingCategories[0]._id;
          await this.fetchRiskRatingsForCategory(this.selectedCategory);
        }
      } catch (error) {
        console.error("Error during component initialization:", error);
        this.errorMessage = "Failed to initialize component. Please try refreshing the page.";
      } finally {
        this.isLoading = false;
      }
    },
    
    validateLoanTypeSelections() {
      this.duplicateLoanTypeError = '';
      
      // Check for duplicate loan types
      const selectedTypes = this.editedCategory.loanTypes;
      
      // If no types selected or undefined, just return
      if (!selectedTypes || selectedTypes.length === 0) {
        return;
      }
      
      const uniqueTypes = [...new Set(selectedTypes)];
      
      if (selectedTypes.length !== uniqueTypes.length) {
        // Find which types are duplicated
        const typeCounts = {};
        const duplicateTypes = [];
        
        selectedTypes.forEach(type => {
          typeCounts[type] = (typeCounts[type] || 0) + 1;
          if (typeCounts[type] > 1 && !duplicateTypes.includes(type)) {
            duplicateTypes.push(type);
          }
        });
        
        // Remove duplicates by keeping only one of each loan type
        this.editedCategory.loanTypes = uniqueTypes;
        
        // Set error message but only temporarily
        this.duplicateLoanTypeError = `Duplicate loan types removed: ${duplicateTypes.join(', ')}`;
        
        // Clear the error after 3 seconds so the save button becomes enabled again
        setTimeout(() => {
          this.duplicateLoanTypeError = '';
        }, 3000);
      }
    },
    
    // Fetch all risk rating categories
    async fetchCategories() {
      try {
        this.isLoading = true;
        this.errorMessage = '';
        console.log('Fetching risk rating categories...');
        
        const response = await creditScoringService.fetchRiskRatingCategories();
        
        if (response && response.result) {
          // Create a fresh copy of the data to avoid any potential reactivity issues
          this.riskRatingCategories = JSON.parse(JSON.stringify(response.value || []));
          console.log('Fetched categories:', this.riskRatingCategories);
          
          // Force the UI to update
          this.$forceUpdate();
          this.originalRiskRatingCategories = JSON.parse(JSON.stringify(this.riskRatingCategories));
        } else {
          // Handle API error
          this.errorMessage = 'Failed to fetch risk rating categories: ' + 
            ((response && response.error && response.error.message) || 'Unknown error');
          console.error('API error fetching categories:', response);
          this.riskRatingCategories = [];
          this.originalRiskRatingCategories = [];
        }
      } catch (error) {
        this.errorMessage = 'An unexpected error occurred while loading risk categories';
        console.error('Error fetching risk rating categories:', error);
        this.riskRatingCategories = [];
        this.originalRiskRatingCategories = [];
      } finally {
        this.isLoading = false;
      }
    },
    
    // Fetch risk ratings for a specific category
    async fetchRiskRatingsForCategory(categoryId) {
      if (!categoryId) return;
      
      try {
        this.isLoading = true;
        this.errorMessage = '';
        console.log(`Fetching risk ratings for category ${categoryId}...`);
        
        const response = await creditScoringService.fetchRiskRatingsByCategory(categoryId);
        
        if (response && response.result) {
          // Create a fresh copy of the data
          this.riskRatings = JSON.parse(JSON.stringify(response.value || []));
          console.log('Risk ratings loaded:', this.riskRatings);
          
          // Force UI update
          this.$forceUpdate();
          this.originalRiskRatings = JSON.parse(JSON.stringify(this.riskRatings));
        } else {
          // Handle API error
          this.errorMessage = 'Failed to fetch risk ratings: ' + 
            ((response && response.error && response.error.message) || 'Unknown error');
          console.error('API error fetching risk ratings:', response);
          this.riskRatings = [];
          this.originalRiskRatings = [];
        }
      } catch (error) {
        this.errorMessage = 'An unexpected error occurred while loading risk ratings';
        console.error('Error fetching risk ratings:', error);
        this.riskRatings = [];
        this.originalRiskRatings = [];
      } finally {
        this.isLoading = false;
      }
    },
    // Select a category (by clicking on a row in the categories table)
    selectCategory(item) {
      console.log('Selected category:', item);
      this.selectedCategory = item._id;
      this.selectedTab = 1; // Switch to the risk ratings tab
      this.fetchRiskRatingsForCategory(item._id);
    },
    
    // Handle category change in dropdown
    async onCategoryChange() {
      await this.fetchRiskRatingsForCategory(this.selectedCategory);
    },
    
    // Category management methods
    openAddCategoryDialog() {
      // Reset validation state but don't reset the form data
      if (this.$refs.categoryForm) {
        this.$refs.categoryForm.resetValidation();
      }
      this.editedCategoryIndex = -1;
      this.editedCategory = Object.assign({}, this.defaultCategory);
      this.categoryFormError = '';
      this.duplicateLoanTypeError = '';
      this.categoryDialog = true;
    },
    
    editCategory(item) {
      this.editedCategoryIndex = this.riskRatingCategories.indexOf(item);
      this.editedCategory = Object.assign({}, item);
      this.categoryFormError = '';
      this.duplicateLoanTypeError = '';
      this.categoryDialog = true;
    },
    
    deleteCategory(item) {
      this.editedCategoryIndex = this.riskRatingCategories.indexOf(item);
      this.editedCategory = Object.assign({}, item);
      this.categoryDialogDelete = true;
    },
    
    closeCategory() {
      this.categoryDialog = false;
      this.$nextTick(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory);
        this.editedCategoryIndex = -1;
        this.categoryFormError = '';
        this.duplicateLoanTypeError = '';
        
        // Reset validation state but don't reset the form data
        if (this.$refs.categoryForm) {
          this.$refs.categoryForm.resetValidation();
        }
      });
    },

    closeCategoryDelete() {
      this.categoryDialogDelete = false;
      this.$nextTick(() => {
        this.editedCategory = Object.assign({}, this.defaultCategory);
        this.editedCategoryIndex = -1;
      });
    },
    
    validateCategoryForm() {
      if (this.$refs.categoryForm && !this.$refs.categoryForm.validate()) {
        return false;
      }
      
      // Check for duplicate name
      const duplicateName = this.riskRatingCategories.find(
        (item, index) => item.name === this.editedCategory.name && index !== this.editedCategoryIndex
      );
      
      if (duplicateName) {
        this.showErrorDialog({
          title: 'Validation Error',
          message: 'Duplicate category name',
          details: `Category name "${this.editedCategory.name}" already exists`,
          type: 'warning'
        });
        return false;
      }
      
      return true;
    },

    async saveCategory() {
      if (!this.validateCategoryForm()) {
        return;
      }
      
      try {
        this.isSaving = true;
        
        if (this.editedCategoryIndex > -1) {
          // Update existing category
          const response = await creditScoringService.updateRiskRatingCategory(
            this.editedCategory._id, 
            this.editedCategory
          );
          
          if (response.result) {
            Object.assign(this.riskRatingCategories[this.editedCategoryIndex], response.value || this.editedCategory);
            this.successMessage = 'Category updated successfully';
          } else {
            this.showErrorDialog({
              title: 'Update Failed',
              message: 'Failed to update category',
              details: response.error && response.error.message ? response.error.message : 'Unknown error',
              type: 'error'
            });
            // Don't close dialog so user can fix and retry
            this.isSaving = false;
            return;
          }
        } else {
          // Add new category
          const response = await creditScoringService.createRiskRatingCategory(this.editedCategory);
          
          if (response.result) {
            // Use the returned category with proper ID
            this.riskRatingCategories.push(response.value);
            this.successMessage = 'Category created successfully';
            
            // Auto-select the new category
            this.selectedCategory = response.value._id;
            this.selectedTab = 1; // Switch to ratings tab
            this.riskRatings = []; // Clear ratings since it's a new category
            this.originalRiskRatings = [];
          } else {
            // Handle error and don't close dialog
            this.isSaving = false;
            return;
          }
        }
        
        // Update original categories to reflect the saved state
        this.originalRiskRatingCategories = JSON.parse(JSON.stringify(this.riskRatingCategories));
        this.closeCategory();
      } catch (error) {
        // Error handling
      } finally {
        this.isSaving = false;
      }
    },

    async deleteCategoryConfirm() {
      try {
        this.isSaving = true;
        
        const response = await creditScoringService.deleteRiskRatingCategory(this.editedCategory._id);
        
        if (response.result) {
          // If the deleted category is the currently selected one
          if (this.selectedCategory === this.editedCategory._id) {
            this.selectedCategory = null;
            this.riskRatings = [];
            this.originalRiskRatings = [];
          }
          
          // Remove from array
          this.riskRatingCategories.splice(this.editedCategoryIndex, 1);
          this.successMessage = 'Category deleted successfully';
          
          // If there are other categories, select the first one
          if (this.riskRatingCategories.length > 0) {
            this.selectedCategory = this.riskRatingCategories[0]._id;
            await this.fetchRiskRatingsForCategory(this.selectedCategory);
          }
        } else {
          // Check for specific error about associated risk ratings
          if (response.error && response.error.response && 
              response.error.response.data && 
              response.error.response.data.error && 
              response.error.response.data.error.includes("associated risk ratings")) {
            
            // Get the number of associated ratings if available
            const associatedRatings = 
              response.error.response.data.associatedRatings || 
              "some";
            
            this.showErrorDialog({
              title: 'Cannot Delete Category',
              message: 'This category has risk ratings associated with it',
              details: `Before deleting this category, you must first delete all ${associatedRatings} associated risk ratings.`,
              type: 'warning',
              suggestion: 'Go to the Risk Ratings tab, select this category, and delete all risk ratings first.',
              showActionButton: true,
              actionButtonText: 'Go to Risk Ratings',
              actionButtonColor: 'primary',
              actionCallback: () => {
                this.selectedTab = 1; // Switch to Risk Ratings tab
                this.selectedCategory = this.editedCategory._id;
                this.closeCategoryDelete();
              }
            });
          } else {
            this.showErrorDialog({
              title: 'Delete Failed',
              message: 'Failed to delete category',
              details: response.error && response.error.message ? response.error.message : 'Unknown error',
              type: 'error'
            });
          }
        }
        
        // Update original categories
        this.originalRiskRatingCategories = JSON.parse(JSON.stringify(this.riskRatingCategories));
      } catch (error) {
        this.showErrorDialog({
          title: 'Unexpected Error',
          message: 'An unexpected error occurred while deleting the category',
          details: error.message,
          type: 'error'
        });
        console.error('Error deleting category:', error);
      } finally {
        this.isSaving = false;
        this.closeCategoryDelete();
      }
    },
    
    // Risk rating management methods
    openAddDialog() {
      this.editedIndex = -1;
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedItem.categoryId = this.selectedCategory;
      this.formError = '';
      this.dialog = true;
    },
    
    editItem(item) {
      this.editedIndex = this.riskRatings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formError = '';
      this.dialog = true;
    },
    
    deleteItem(item) {
      this.editedIndex = this.riskRatings.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    
    async deleteItemConfirm() {
      try {
        this.isSaving = true;
        
        const response = await creditScoringService.deleteRiskRating(this.editedItem._id);
        
        if (response.result) {
          this.riskRatings.splice(this.editedIndex, 1);
          this.successMessage = 'Risk rating deleted successfully';
        } else {
          this.errorMessage = 'Failed to delete risk rating: ' + ((response.error && response.error.message) || 'Unknown error');
        }
        
        // Update original risk ratings
        this.originalRiskRatings = JSON.parse(JSON.stringify(this.riskRatings));
      } catch (error) {
        this.errorMessage = 'An unexpected error occurred';
        console.error('Error deleting risk rating:', error);
      } finally {
        this.isSaving = false;
        this.closeDelete();
      }
    },
    
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
        this.formError = '';
        
        // Reset validation state
        if (this.$refs.form) {
          this.$refs.form.reset(); 
          this.$refs.form.resetValidation();
        }
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    validateForm() {
      if (this.$refs.form && !this.$refs.form.validate()) {
        return false;
      }
      
      // Validate score ranges (no overlaps)
      if (this.editedItem.minScore >= this.editedItem.maxScore) {
        this.showErrorDialog({
          title: 'Validation Error',
          message: 'Invalid score range',
          details: 'Minimum score must be less than maximum score',
          type: 'warning'
        });
        return false;
      }
      
      // Check for duplicate grade within this category
      const duplicateGrade = this.riskRatings.find(
        (item, index) => item.grade === this.editedItem.grade && index !== this.editedIndex &&  
        item.categoryId === this.selectedCategory
      );
      
      if (duplicateGrade) {
        this.showErrorDialog({
          title: 'Validation Error',
          message: 'Duplicate grade',
          details: `Grade "${this.editedItem.grade}" already exists in this category`,
          type: 'warning'
        });
        return false;
      }
      
      // Check for duplicate definition within this category
      const duplicateDefinition = this.riskRatings.find(
        (item, index) => item.definition === this.editedItem.definition && 
        index !== this.editedIndex && item.categoryId === this.selectedCategory
      );
      
      if (duplicateDefinition) {
        this.showErrorDialog({
          title: 'Validation Error',
          message: 'Duplicate definition',
          details: `Definition "${this.editedItem.definition}" already exists in this category`,
          type: 'warning'
        });
        return false;
      }
      
      // Check for overlapping score ranges within this category
      const overlappingRange = this.riskRatings.find(
        (item, index) => index !== this.editedIndex && 
                      item.categoryId === this.selectedCategory &&
                      ((this.editedItem.minScore >= item.minScore && this.editedItem.minScore <= item.maxScore) ||
                      (this.editedItem.maxScore >= item.minScore && this.editedItem.maxScore <= item.maxScore) ||
                      (this.editedItem.minScore <= item.minScore && this.editedItem.maxScore >= item.maxScore))
      );
      
      if (overlappingRange) {
        this.showErrorDialog({
          title: 'Validation Error',
          message: 'Overlapping score range',
          details: `Score range overlaps with grade "${overlappingRange.grade}"`,
          type: 'warning'
        });
        return false;
      }
      
      return true;
    },

    async save() {
      if (!this.validateForm()) {
        return;
      }
      
      try {
        this.isSaving = true;
        
        // Ensure the category ID is set
        this.editedItem.categoryId = this.selectedCategory;
        
        if (this.editedIndex > -1) {
          // Update existing item
          const response = await creditScoringService.updateRiskRating(
            this.editedItem._id, 
            this.editedItem
          );
          
          if (response.result) {
            // Update the item in the local array with the response from the server
            Object.assign(this.riskRatings[this.editedIndex], response.value || this.editedItem);
            this.successMessage = 'Risk rating updated successfully';
            
            // Call the database save API to ensure all changes are persisted
            await this.saveRiskRatingsToDatabase();
          } else {
            // Handle error and don't close dialog
            this.isSaving = false;
            return;
          }
        } else {
          // Add new item
          const response = await creditScoringService.createRiskRating(this.editedItem);
          
          if (response.result) {
            // Add the new item to the local array
            this.riskRatings.push(response.value || this.editedItem);
            this.successMessage = 'Risk rating added successfully';
            
            // Call the database save API to ensure all changes are persisted
            await this.saveRiskRatingsToDatabase();
          } else {
            // Handle error and don't close dialog
            this.isSaving = false;
            return;
          }
        }
        
        // Update original risk ratings to reflect the saved state
        this.originalRiskRatings = JSON.parse(JSON.stringify(this.riskRatings));
        this.close();
      } catch (error) {
        // Error handling
      } finally {
        this.isSaving = false;
      }
    },

    // Helper method to save risk ratings to database
    async saveRiskRatingsToDatabase() {
      if (!this.selectedCategory || this.riskRatings.length === 0) {
        return;
      }
      
      try {
        const response = await creditScoringService.saveRiskRatingsForCategory(
          this.selectedCategory,
          this.riskRatings
        );
        
        if (!response.result) {
          console.error('Warning: Failed to save risk ratings to database:', response.error);
        }
      } catch (error) {
        console.error('Error saving risk ratings to database:', error);
      }
    },

    async saveSettings() {
      this.isSaving = true;
      this.errorMessage = '';
      this.successMessage = '';
      
      try {
        if (this.selectedTab === 0) {
          this.successMessage = 'Risk rating categories saved successfully';
        } else if (this.selectedTab === 1 && this.selectedCategory && this.riskRatings.length > 0) {
          const unsavedRatings = this.riskRatings.filter(rating => !rating._id);
          
          if (unsavedRatings.length > 0) {
            // Save all risk ratings in bulk
            const response = await creditScoringService.saveRiskRatingsForCategory(
              this.selectedCategory,
              this.riskRatings
            );
            
            if (response.result) {
              // Update the risk ratings with the returned data
              this.riskRatings = response.value || [];
              this.originalRiskRatings = JSON.parse(JSON.stringify(this.riskRatings));
              this.successMessage = 'Risk ratings saved successfully';
            } else {
              this.showErrorDialog({
                title: 'Save Failed',
                message: 'Failed to save risk ratings',
                details: response.error && response.error.message ? response.error.message : 'Unknown error',
                type: 'error',
                showRetryButton: true,
                retryCallback: this.saveSettings
              });
              return;
            }
          } else {
            this.successMessage = 'No changes to save';
          }
        } else {
          this.successMessage = 'No changes to save';
        }
      } catch (error) {
        this.showErrorDialog({
          title: 'Save Error',
          message: 'An unexpected error occurred while saving settings',
          details: error.message,
          type: 'error',
          showRetryButton: true,
          retryCallback: this.saveSettings
        });
        console.error('Error saving settings:', error);
      } finally {
        this.isSaving = false;
      }
    },
    
    calculateBandWidth(rating) {
      if (!rating) return 0;
      
      const range = rating.maxScore - rating.minScore;
      return (range / 1000) * 100; // Scale to percentage of total range (0-1000)
    },
    
    getBandColor(index) {
      // Return different colors for different bands
      const colors = ['#4CAF50', '#8BC34A', '#FFC107', '#FF9800', '#F44336'];
      return colors[index % colors.length];
    },
    
    navigateTo(route) {
      const path = `/app/credit-scoring/${route}`;
      this.$router.push(path).catch(err => {
        if (err.name !== 'NavigationDuplicated') {
          console.error('Navigation error:', err);
        }
      });
    },

    showErrorDialog(options) {
      // Default values for options
      const errorTitle = options.title || 'Error';
      const errorMessage = options.message || 'An error occurred';
      const errorDetails = options.details || '';
      const errorType = options.type || 'error';
      const suggestion = options.suggestion || '';
      const showRetryButton = options.showRetryButton || false;
      
      // Make sure we have a valid action function if we're showing the action button
      const hasValidAction = typeof options.actionCallback === 'function';
      const showActionButton = options.showActionButton && hasValidAction;
      const actionButtonText = options.actionButtonText || 'Action';
      const actionButtonColor = options.actionButtonColor || 'primary';
      
      // Set error dialog properties
      this.errorDialog = {
        show: true,
        title: errorTitle,
        message: errorMessage,
        details: errorDetails,
        type: errorType,
        suggestion: suggestion,
        showRetryButton: showRetryButton,
        showActionButton: showActionButton,
        actionButtonText: actionButtonText,
        actionButtonColor: actionButtonColor,
        retryCallback: options.retryCallback || null,
        actionCallback: hasValidAction ? options.actionCallback : null
      };
      
      // Warn if action button is requested but no valid action provided
      if (options.showActionButton && !hasValidAction) {
        console.warn('showActionButton is true but no valid action handler was provided');
      }
    },
    handleErrorRetry() {
      if (typeof this.errorDialog.retryCallback === 'function') {
        this.errorDialog.retryCallback();
      }
    },

    handleErrorAction() {
      if (typeof this.errorDialog.actionCallback === 'function') {
        this.errorDialog.actionCallback();
      }
    },

    openGenerateRangesDialog() {
      this.rangeGenerator = {
        startValue: 0,
        numRanges: 5,
        interval: 200,
        useLetterGrades: true
      };
      this.rangeGeneratorError = '';
      this.generateRangesDialog = true;
    },

    closeGenerateRangesDialog() {
      this.generateRangesDialog = false;
      this.rangeGeneratorError = '';

      // Reset the form data to default values
      this.rangeGenerator = {
        startValue: 0,
        numRanges: 5,
        interval: 200,
        useLetterGrades: true
      };
    },

    generateRiskRatingDefinition(index, total) {
      // Generate appropriate definitions based on position in the range
      if (index === 0) return 'Excellent';
      if (index === 1) return 'Good';
      if (index === total - 2) return 'Fair';
      if (index === total - 1) return 'Poor';
      
      // For ranges in the middle
      const middleRanges = ['Very Good', 'Above Average', 'Average', 'Below Average'];
      const middleIndex = index - 2;
      
      if (middleIndex >= 0 && middleIndex < middleRanges.length) {
        return middleRanges[middleIndex];
      }
      
      return `Risk Level ${index + 1}`;
    },

    getLetterGrade(index, total) {
      // Generate letter grades A, B, C, etc.
      // For more than 26 grades, use AA, BB, etc.
      if (index < 26) {
        return String.fromCharCode(65 + index);
      } else {
        // For more than 26 grades (unlikely but just in case)
        const letter = String.fromCharCode(65 + (index % 26));
        return letter + letter;
      }
    },
    
    async generateRanges() {
      if (!this.canGenerateRanges) {
        this.rangeGeneratorError = 'Please correct the errors before generating ranges';
        return;
      }
      
      try {
        this.isSaving = true;
        
        const start = this.rangeGenerator.startValue;
        const interval = this.rangeGenerator.interval;
        const numRanges = this.rangeGenerator.numRanges;
        const useLetterGrades = this.rangeGenerator.useLetterGrades;
        
        // Calculate the max possible value
        const maxPossibleValue = start + (interval * numRanges);
        
        // Ensure we don't exceed the maximum allowed score (1000)
        if (maxPossibleValue > 1000) {
          this.rangeGeneratorError = `Total range (${maxPossibleValue}) exceeds maximum score (1000). Please adjust your parameters.`;
          this.isSaving = false;
          return;
        }
        
        // Generate the new risk ratings
        const newRiskRatings = [];
        
        for (let i = 0; i < numRanges; i++) {
          const minScore = start + (i * interval);
          let maxScore = start + ((i + 1) * interval) - 1;
          
          // Make sure the last range includes the maximum value
          if (i === numRanges - 1) {
            maxScore = 1000;
          }
          
          // Generate appropriate grade based on settings
          const grade = useLetterGrades 
            ? this.getLetterGrade(i, numRanges)
            : `Grade ${i + 1}`;
          
          // Generate definition
          const definition = this.generateRiskRatingDefinition(i, numRanges);
          
          newRiskRatings.push({
            categoryId: this.selectedCategory,
            grade: grade,
            definition: definition,
            minScore: minScore,
            maxScore: maxScore
          });
        }
        
        // Replace the current risk ratings with the new ones
        this.riskRatings = newRiskRatings;
        
        // Save the generated risk ratings to the database
        try {
          const response = await creditScoringService.saveRiskRatingsForCategory(
            this.selectedCategory,
            this.riskRatings
          );
          
          if (response.result) {
            // Update with the returned data from the server
            this.riskRatings = response.value || newRiskRatings;
            this.originalRiskRatings = JSON.parse(JSON.stringify(this.riskRatings));
            
            // Close the dialog and show success message
            this.generateRangesDialog = false;
            this.successMessage = `Generated and saved ${numRanges} risk rating ranges successfully.`;
          } else {
            // Show error if save failed
            this.rangeGeneratorError = "Failed to save generated ranges: ".concat((response.error && response.error.message) || 'Unknown error');
            console.error('Failed to save generated ranges:', response.error);
          }
        } catch (error) {
          this.rangeGeneratorError = `Error saving generated ranges: ${error.message}`;
          console.error('Error saving generated ranges:', error);
        }
      } catch (error) {
        this.rangeGeneratorError = `Error generating ranges: ${error.message}`;
        console.error('Error generating ranges:', error);
      } finally {
        this.isSaving = false;
      }
    }

  }
};
</script>

<style scoped>
.score-bands-container {
  display: flex;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 16px;
}

.score-band {
  height: 100%;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}

.band-label {
  color: white;
  text-align: center;
  font-size: 12px;
  padding: 4px;
  text-shadow: 0 0 2px rgba(0,0,0,0.7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>